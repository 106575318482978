<template>
    <section class="blockElement space marketPortfolio">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 d-flex align-items-center mb-4">
                    <router-link to="/rates" class="viewAll gray medium ps-1 pe-3 f-14 d-flex align-items-center"><vue-feather class="ms-1" type="chevron-left" size="16"></vue-feather>{{$t('marketChart.chartText1')}} </router-link>
                </div>
                <div class="col-12 col-lg-8 emptyMinHeight">
                    <div v-if="Object.keys(store.assetDetail).length">
                        <div class="mktReader position-relative mb-4 pb-3">
                            
                            <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                            <div class="chartIQ" :id="'AssetChart'+store.assetDetail.id" style="height: 400px; width: 100%;"></div>
                            <ul class="d-flex align-items-center justify-content-evenly">
                                <li v-for="list in dateDrop" :key="list.key">
                                    <a class="f-14 gray selectDay" @click.prevent="dateDropSelected = list;changeChart(dateDropSelected.key)" :class="[{ 'active': dateDropSelected.value == list.value }]" href="javascript:void(0);">{{ list.value }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="myTrade" v-if="store.user.access_token">
                            <h5 class="mb-3 f-22 medium">{{$t('marketChart.chartText2')}}</h5>
                            <div class="bg-white boxed cardBorder p-0">
                                <ul id="tabs-nav" class="zuluTabs smallTab d-flex align-items-center border-bottom mb-0">
                                    <li :class="[{'active' : tradeType == 'open'}]"><a href="javascript:void(0)" @click="tradeType = 'open';getCurrecnyTrades()">{{$t('marketChart.chartText3')}}</a></li>
                                    <li :class="[{'active' : tradeType == 'pending'}]"><a href="javascript:void(0)" @click="tradeType = 'pending';getCurrecnyTrades()">{{$t('marketChart.chartText4')}}</a></li>
                                    <li :class="[{'active' : tradeType == 'history'}]"><a href="javascript:void(0)" @click="tradeType = 'history';getCurrecnyTrades()">{{$t('marketChart.chartText5')}}</a></li>
                                </ul>
                                <div class="dataTable table-responsive position-relative">
                                    <Loader :classname="'innerLoader'" v-if="store.tradesLoading"></Loader>
                                    <table id="example" class="table currencyTable" v-if="tradeType == 'open'">
                                        <thead>
                                            <tr>
                                                <th class="ps-md-4 pointer desc">{{$t('marketChart.chartText6')}}</th>
                                                <th class="pointer desc">{{$t('marketChart.chartText7')}}</th>
                                                <th class="pointer desc">{{$t('marketChart.chartText8')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="Object.keys(store.assetTradesList).length && store.assetTradesList.content && store.assetTradesList.content.length">
                                            <tr v-for="item,key in store.assetTradesList.content" :key="key">
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div class="nameView">
                                                            <div class="d-flex align-items-center">
                                                                <p class="mb-0 f-14 medium">{{item.currencyName}}</p>
                                                                <!-- <span class="cfdTrade ms-1">CFD</span> -->
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                <p class="mb-0 midgray f-11 medium">{{dateFormat(item.dateTime) || '-'}}, {{timeFormat(item.dateTime) || '-'}}</p>
                                                                <span class="status" :class="[{'buy':item.tradeType=='BUY'},{'sell':item.tradeType=='SELL'}]">{{item.tradeType}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td :class="(parseFloat(item.floatingPnl) >= 0.0) ? 'green' : 'red'">{{formatPrice(item.floatingPnl)}}</td>
                                                <td><a href="javascript:void(0)" @click="closeTrade(item)"><vue-feather class="red" size="16" type="trash-2"></vue-feather></a></td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="3">
                                                    <Nodata></Nodata>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table id="example" class="table currencyTable" v-if="tradeType == 'pending'">
                                        <Loader :classname="'innerLoader'" v-if="store.tradesLoading"></Loader>
                                        <thead>
                                            <tr>
                                                <th class="ps-md-4 pointer desc">{{$t('marketChart.chartText9')}}</th>
                                                <th class="pointer desc">{{$t('marketChart.chartText10')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="Object.keys(store.assetPendindTradesList).length && store.assetPendindTradesList.content && store.assetPendindTradesList.content.length">
                                            <tr v-for="item,key in store.assetPendindTradesList.content" :key="key">
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div class="nameView">
                                                            <div class="d-flex align-items-center">
                                                                <p class="mb-0 f-14 medium">{{item.currencyName}}</p>
                                                                <!-- <span class="cfdTrade ms-1">CFD</span> -->
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                <p class="mb-0 midgray f-11 medium">{{dateFormat(item.dateTime) || '-'}}, {{timeFormat(item.dateTime) || '-'}}</p>
                                                                <span class="status" :class="[{'buy':item.tradeType=='BUY'},{'sell':item.tradeType=='SELL'}]">{{item.tradeType}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{item.entryRate}}</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="2">
                                                    <Nodata></Nodata>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table id="example" class="table currencyTable" v-if="tradeType == 'history'">
                                        <Loader :classname="'innerLoader'" v-if="store.tradesLoading"></Loader>
                                        <thead>
                                            <tr>
                                                <th class="ps-md-4 pointer desc">{{$t('marketChart.chartText6')}}</th>
                                                <th class="pointer desc">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="Object.keys(store.assetTradesHistoryList).length && store.assetTradesHistoryList.content && store.assetTradesHistoryList.content.length">
                                            <tr v-for="item,key in store.assetTradesHistoryList.content" :key="key">
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div class="nameView">
                                                            <div class="d-flex align-items-center">
                                                                <p class="mb-0 f-14 medium">{{item.currency}}</p>
                                                                <!-- <span class="cfdTrade ms-1">CFD</span> -->
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                <p class="mb-0 midgray f-11 medium">{{dateFormat(item.dateClosed) || '-'}}, {{timeFormat(item.dateClosed) || '-'}}</p>
                                                                <span class="status" :class="[{'buy':item.tradeType=='BUY'},{'sell':item.tradeType=='SELL'}]">{{item.tradeType}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p class="mb-0 medium f-14 pnl d-flex align-items-center"> <span class="me-1">P/L : </span><span :class="(parseFloat(item.grossPnl) >= 0.0) ? 'green' : 'red'">{{formatPrice(item.grossPnl)}}</span></p>
                                                    <p class="mb-0 medium f-14 pnl d-flex align-items-center"><span class="me-1">Lots : </span> <span :class="(parseFloat(item.grossPnl) >= 0.0) ? 'green' : 'red'" >{{item.lots}} </span></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="2">
                                                    <Nodata></Nodata>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 emptyMinHeight">
                    <div>
                        <h6 class="f-22 bold" v-if="Object.keys(store.assetDetail).length && store.assetDetail.symbol">{{$t('marketChart.chartText11')}} {{store.assetDetail.symbol}}</h6>
                        <div class="boxed bg-white cardBorder newFillTabs maxHeight630">
                            <ul id="tabs-nav" class="zuluTabs d-flex align-items-center position-sticky top0 px-0 mb-4">
                                <!-- <li :class="(taskTab == 'Information')?'active':''"><a @click.prevent="taskTab = 'Information'" class="f-16" href="javascript:void(0)">Information</a></li>
                                <li :class="(taskTab == 'Tesla')?'active':''"><a @click.prevent="taskTab = 'Tesla'" class="f-16" href="javascript:void(0)">#Tesla</a></li> -->
                                <li :class="(taskTab == 'Market')?'active':''"><a @click.prevent="taskTab = 'Market'" class="f-16" href="javascript:void(0)">{{$t('marketChart.chartText12')}}</a></li>
                            </ul>
                            <div class="tabShowOne" v-if="taskTab == 'Tesla'">
                                <div class="card-body mb-3 border-0">
                                    <div class="d-flex align-items-center justify-content-between mb-2">
                                        <div class="d-flex align-items-center">
                                            <span class="me-2"><v-lazy-image width="40" height="40" class="rounded-circle d-block" src="/assets/images/feedNews.jpg" :alt="$t('marketChart.chartText13')" :title="$t('marketChart.chartText13')" /></span>
                                            <div class="flex-shrink-0">
                                                <h6 class="medium f-15 mb-0">{{$t('marketChart.chartText14')}}</h6>
                                                <p class="mb-0 line-1 gray small">{{$t('marketChart.chartText15')}}</p>
                                            </div>
                                        </div>
                                        <div class="secondary gray f-12 d-flex align-items-center">
                                            <span class="mb-0 me-2 line-1 small">{{$t('marketChart.chartText16')}}</span>
                                            <a class="d-flex align-items-center" href="javascript:void(0)"><vue-feather size="17" type="more-vertical"></vue-feather> </a>
                                        </div>
                                    </div>
                                    <div class="customPad viewNews">
                                        <p class="mb-1 f-15 neutralGrey">{{$t('marketChart.chartText17')}}</p>
                                        <ul class="commentArea d-flex align-items-center mt-2">
                                            <li>
                                                <a class="d-flex align-items-center" href="javascript:void(0)"><vue-feather class="me-1" size="17" type="heart"></vue-feather><span>0</span></a>
                                            </li>
                                            <li>
                                                <a class="d-flex align-items-center" href="javascript:void(0)"><vue-feather class="me-1" size="17" type="message-square"></vue-feather><span>0</span></a>
                                            </li>
                                            <li>
                                                <a class="d-flex align-items-center" href="javascript:void(0)"><vue-feather size="17" type="share-2"></vue-feather></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card-body mb-3 border-0">
                                    <div class="d-flex align-items-center justify-content-between mb-2">
                                        <div class="d-flex align-items-center">
                                            <span class="me-2"><v-lazy-image width="40" height="40" class="rounded-circle d-block" src="/assets/images/feedNews.jpg" :alt="$t('marketChart.chartText13')" :title="$t('marketChart.chartText13')" /></span>
                                            <div class="flex-shrink-0">
                                                <h6 class="medium f-15 mb-0">{{$t('marketChart.chartText14')}}</h6>
                                                <p class="mb-0 line-1 gray small">{{$t('marketChart.chartText15')}}</p>
                                            </div>
                                        </div>
                                        <div class="secondary gray f-12 d-flex align-items-center">
                                            <span class="mb-0 me-2 line-1 small">{{$t('marketChart.chartText16')}}</span>
                                            <a class="d-flex align-items-center" href="javascript:void(0)"><vue-feather size="17" type="more-vertical"></vue-feather> </a>
                                        </div>
                                    </div>
                                    <div class="customPad viewNews">
                                        <p class="mb-1 f-15 neutralGrey">{{$t('marketChart.chartText17')}}</p>
                                        <ul class="commentArea d-flex align-items-center mt-2">
                                            <li>
                                                <a class="d-flex align-items-center" href="javascript:void(0)"><vue-feather class="me-1" size="17" type="heart"></vue-feather><span>0</span></a>
                                            </li>
                                            <li>
                                                <a class="d-flex align-items-center" href="javascript:void(0)"><vue-feather class="me-1" size="17" type="message-square"></vue-feather><span>0</span></a>
                                            </li>
                                            <li>
                                                <a class="d-flex align-items-center" href="javascript:void(0)"><vue-feather size="17" type="share-2"></vue-feather></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card-body mb-3 border-0">
                                    <div class="d-flex align-items-center justify-content-between mb-2">
                                        <div class="d-flex align-items-center">
                                            <span class="me-2"><v-lazy-image width="40" height="40" class="rounded-circle d-block" src="/assets/images/feedNews.jpg" :alt="$t('marketChart.chartText13')" :title="$t('marketChart.chartText13')" /></span>
                                            <div class="flex-shrink-0">
                                                <h6 class="medium f-15 mb-0">{{$t('marketChart.chartText14')}}</h6>
                                                <p class="mb-0 line-1 gray small">{{$t('marketChart.chartText15')}}</p>
                                            </div>
                                        </div>
                                        <div class="secondary gray f-12 d-flex align-items-center">
                                            <span class="mb-0 me-2 line-1 small">{{$t('marketChart.chartText16')}}</span>
                                            <a class="d-flex align-items-center" href="javascript:void(0)"><vue-feather size="17" type="more-vertical"></vue-feather> </a>
                                        </div>
                                    </div>
                                    <div class="customPad viewNews">
                                        <p class="mb-1 f-15 neutralGrey">{{$t('marketChart.chartText17')}}</p>
                                        <ul class="commentArea d-flex align-items-center mt-2">
                                            <li>
                                                <a class="d-flex align-items-center" href="javascript:void(0)"><vue-feather class="me-1" size="17" type="heart"></vue-feather><span>0</span></a>
                                            </li>
                                            <li>
                                                <a class="d-flex align-items-center" href="javascript:void(0)"><vue-feather class="me-1" size="17" type="message-square"></vue-feather><span>0</span></a>
                                            </li>
                                            <li>
                                                <a class="d-flex align-items-center" href="javascript:void(0)"><vue-feather size="17" type="share-2"></vue-feather></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="tabShowOne" v-if="taskTab == 'Market'">
                                <ul class="borderList mb-0" v-if="Object.values(store.assetNewsList).length && Object.values(store.assetNewsList)[0].length">
                                    <li v-for="list,index in Object.values(store.assetNewsList)[0]" :key="index">
                                        <div class="d-flex align-items-start listView pointer" @click="showNews = list">
                                            <span class="circleImg flex-none d-inline-block me-2">
                                                <v-lazy-image class="h-100" :src="list.image" :alt="$t('marketChart.chartText18')" :title="$t('marketChart.chartText18')" v-if="list.image"/>
                                                <v-lazy-image class="h-100" :src="list.image_url" :alt="$t('marketChart.chartText18')" :title="$t('marketChart.chartText18')" v-else />
                                            </span>
                                            <div class="text-start calcWidth">
                                                <h6 class="d-block bold mb-1 f-16">{{list.title}}</h6>
                                                <p class="mb-0 f-15 neutralGrey ellipsLine hideInnerContent" v-if="list.source == 'FXS' && list.text" v-html="list.text"></p>
                                                <p class="mb-0 f-15 neutralGrey ellipsLine hideInnerContent" v-else>{{(list.source == 'TE') ? list.description : list.summary}}</p>
                                            </div>
                                            <time class="position-absolute topRight f-12 midgray">{{formatTime(list.published,'now',0)}}</time>
                                        </div>
                                        <div class="viewNewsPop mt-2 mb-3 position-relative" v-if="Object.keys(showNews).length && showNews._id ==  list._id">
                                            <a class="roundClose" href="javascript:void(0)" @click="showNews = {}"><vue-feather size="16" type="x"></vue-feather></a>
                                            <div class="card-body mb-4">
                                                <!-- <v-lazy-image src="/assets/images/newsBlock.png" alt="News Images" class="img-fluid mb-2" /> -->
                                                <v-lazy-image :src="showNews.image" :alt="$t('marketChart.chartText18')" class="img-fluid mb-2 w-100 br-8"  v-if="showNews.image"/>
                                                <v-lazy-image :src="showNews.image_url" :alt="$t('marketChart.chartText18')" class="img-fluid mb-2 w-100 br-8"  v-else />
                                                <p class="f-15 medium mb-1">{{showNews.title}}</p>
                                                <p class="mb-1 f-14 neutralGrey" v-if="showNews.source == 'FXS' && showNews.text" v-html="showNews.text"></p>
                                                <p class="mb-1 f-14 neutralGrey" v-else>{{(showNews.source == 'TE') ? showNews.description : showNews.summary}}</p>
                                               
                                            </div>
                                        </div>

                                    </li>
                                </ul>
                                <Nodata v-else></Nodata>
                            </div>
                            <div class="tabShowOne" v-if="taskTab == 'Information'">
                                <ul class="infoList">
                                    <li>
                                        <span class="neutralGrey f-14">{{$t('marketChart.chartText19')}}</span>
                                        <p class="mb-0 f-18 medium">{{$t('marketChart.chartText20')}}</p>
                                    </li>
                                    <li>
                                        <span class="neutralGrey f-14">{{$t('marketChart.chartText21')}}</span>
                                        <p class="mb-0 f-18 medium">{{$t('marketChart.chartText18')}}$191.11 - $196.25</p>
                                    </li>
                                    <li>
                                        <span class="neutralGrey f-14">{{$t('marketChart.chartText22')}}</span>
                                        <p class="mb-0 f-18 medium">{{$t('marketChart.chartText23')}}</p>
                                    </li>
                                    <li>
                                        <span class="neutralGrey f-14">{{$t('marketChart.chartText24')}}</span>
                                        <p class="mb-0 f-18 medium">{{$t('marketChart.chartText25')}}</p>
                                    </li>
                                    <li>
                                        <span class="neutralGrey f-14">{{$t('marketChart.chartText26')}}</span>
                                        <p class="mb-0 f-18 medium">{{$t('marketChart.chartText27')}}</p>
                                    </li>
                                    <li>
                                        <span class="neutralGrey f-14">{{$t('marketChart.chartText28')}}</span>
                                        <p class="mb-0 f-18 medium">{{$t('marketChart.chartText29')}}</p>
                                    </li>
                                    <li>
                                        <span class="neutralGrey f-14">{{$t('marketChart.chartText30')}}</span>
                                        <p class="mb-0 f-18 medium">-</p>
                                    </li>
                                    <li>
                                        <span class="neutralGrey f-14">{{$t('marketChart.chartText31')}}</span>
                                        <p class="mb-0 f-18 medium">{{$t('marketChart.chartText32')}}</p>
                                    </li>
                                </ul>
                                <div class="aboutContent">
                                    <h5>{{$t('marketChart.chartText33')}}</h5>
                                    <p> {{$t('marketChart.chartText34')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <buySell v-if="openPopup" /> -->

            </div>
        </div>
         //<loginPopup v-if="showLoginPopup"></loginPopup>
    </section>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    //import PriceFeed from "@/store/stomp";
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import moment from "moment";
   // import buySell from './buy-sell/buy-sell.vue'
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                taskTab: "Market",
                CurrencyID: "",
                errImage: [],
                dateDrop: [
                    { key: 1, value: "1D" },
                    { key: 7, value: "7D" },
                    { key: 30, value: "1M" },
                    { key: 365, value: "1Y" },
                    { key: 10000, value: "YTD" },
                ],
                dateDropSelected: { key: 365, value: "1Y" },
                tradeType: "open",
                openPopup : '',
                detail : {},
                showLoginPopup: false,
                showNews : {}

            };
        },
       
        watch:{
            '$route.params.id'(){
                if(this.$route.name == 'mobileMarket'){
                    this.getCurrecnyDetail();
                }
            }
        },
        methods: {
            changeChart(key){
                if(Object.keys(this.store.assetDetail).length){
                    let chart = []
                    if(key == 1){
                        if(this.store.assetDetail.hourlyChart && this.store.assetDetail.hourlyChart.length){
                            this.store.assetDetail.hourlyChart.map(i =>{
                                let temp = {
                                date: new Date((moment(`${i.datetime[0]}-${i.datetime[1]}-${i.datetime[2]} ${i.datetime[3]}:00:00`, 'YYYY-MM-DD HH:mm:ss').toISOString())).getTime(),
                                value: i.priceClose
                                }
                                chart.push(temp)
                            })
                            this.drawMarketChart(`AssetChart${this.store.assetDetail.id}`, chart);
                        }
                    }else{
                        if(this.store.assetDetail.dailyChart && this.store.assetDetail.dailyChart.length){
                             this.store.assetDetail.dailyChart.map(i =>{
                                let temp = {
                                date: new Date(moment(`${i.datetime[0]}-${i.datetime[1]}-${i.datetime[2]}`, 'YYYY-MM-DD').format('YYYY-MM-DD')).getTime(),
                                value: i.priceClose
                                }
                                chart.push(temp)
                            }) 
                            if(key == 7){
                                chart = chart.slice(-5)
                            }else if(key == 30){
                                chart = chart.slice(-30)
                            }else if(key == 365){
                                chart = chart.slice(-365)
                            }else if(key == 10000){
                                let beginning = moment().diff(moment().startOf('year'), 'days')
                                chart = chart.slice(-beginning)
                            } 
                            this.drawMarketChart(`AssetChart${this.store.assetDetail.id}`, chart);                                                            
                        }
                    }
                }
            },
            checKLogin(type) {
                if (!this.store.user.access_token) {
                  this.showLoginPopup = true;
                } else {
                  this.showLoginPopup = false;
                  this.openPopup = type
                }
            },
            closeTrade(item){
                let form = {
                    'requestedPrice' : item.currentRate
                }
                this.store.closeOpenOrder(form,true,item.brokerTicket).then((response) =>{
                    if(response === true){
                        this.getCurrecnyTrades()
                    }
                })
            },
            formatPrice(price) {
                if (price < 0) {
                    return `-$${parseFloat(Math.abs(price)).toFixed(2)}`;
                } else {
                    return `$${parseFloat(price).toFixed(2)}`;
                }
            },
            formatTime(date,format,offset) {
                if(!date) return 'N/A'
                let d = moment(`${date.split(' ')[0]} ${date.split(' ')[1]}`, 'YYYY-MM-DD HH:mm:ss')
                let tz = offset != 0 ? offset : -(new Date().getTimezoneOffset())
                d.add(tz, 'minutes')
                return d.fromNow()
            },
            dateFormat(date) {
                if (date) {
                    let format = moment(date).toString();
                    return moment(format).format("YYYY-MM-DD");
                }
            },
            timeFormat(date) {
                if (date) {
                    let format = moment(date).toString();
                    return moment(format).format("hh:mm:ss");
                }
            },
            getCurrecnyDetail() {
                let formData = {
                    from: "",
                    to: "",
                    marketIds: [this.$route.params.id],
                };
                formData["from"] = moment().subtract(1, "year").format("YYYY-MM-DD");
                formData["to"] = moment().format("YYYY-MM-DD");
                this.store.getAssetDetail(formData, true, this).then(() => {
                    if (!this.store.singleLoading && Object.keys(this.store.assetDetail).length) {
                        this.changeChart(365)
                    }
                });
            },
            drawMarketChart(id, data) {
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === id);
                d?.dispose();
                let root = am5.Root.new(id);
                root.interfaceColors.set("grid", am5.color(0xffffff));

                root.setThemes([am5themes_Animated.new(root)]);
                root.numberFormatter.setAll({
                    numberFormat: "#.##",
                    //   smallNumberThreshold: 0.001
                });
                // Create drawprofitChart
                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        focusable: true,
                    })
                );

                // Create axes
                let xAxis = chart.xAxes.push(
                    am5xy.GaplessDateAxis.new(root, {
                        groupData: true,
                        baseInterval: {
                            timeUnit: this.detail.dailyChart && !this.detail.dailyChart.length ? "hour" : "day",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {
                            minGridDistance: 50,
                        }),
                        //tooltip: am5.Tooltip.new(root, {})
                    })
                );

                // xAxis.get("dateFormats")["day"] = "MM/dd";
                // xAxis.get("periodChangeDateFormats")["day"] = "MMMM";

                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );
                let yRenderer = yAxis.get("renderer");
                yRenderer.grid.template.setAll({
                    stroke: am5.color(0x666666),
                    strokeWidth: 1,
                });

                chart.get("colors").set("colors", [am5.color(0xff7200)]);

                var legend = chart.bottomAxesContainer.children.push(
                    am5.Legend.new(root, {
                        x: am5.percent(50),
                        centerX: am5.percent(50),
                        layout: root.horizontalLayout,
                        useDefaultMarker: true,
                        clickTarget: "none",
                        // paddingTop: 15,
                    })
                );

                legend.labels.template.setAll({
                    fontSize: 12,
                    fontWeight: "600",
                });
                legend.markerRectangles.template.setAll({
                    cornerRadiusTL: 20,
                    cornerRadiusTR: 20,
                    cornerRadiusBL: 20,
                    cornerRadiusBR: 20,
                });
                legend.markers.template.setAll({
                    width: 13,
                    height: 13,
                });

                var series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        legendLabelText: this.store.assetDetail.symbol,
                        minBulletDistance: 10,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "value",
                        valueXField: "date",
                        tooltip: am5.Tooltip.new(root, {
                            pointerOrientation: "horizontal",
                            labelText: "{valueY}",
                        }),
                    })
                );
                series.fills.template.setAll({
                    visible: true,
                });

                series.fills.template.set(
                    "fillGradient",
                    am5.LinearGradient.new(root, {
                        stops: [
                            {
                                opacity: 0.7,
                            },
                            {
                                opacity: 0.1,
                            },
                        ],
                    })
                );

                series.strokes.template.setAll({
                    strokeWidth: 2,
                });

                // Set data
                series.data.setAll(data);
                legend.data.push(series);

                // Make stuff animate on load
                series.appear(1000);

                // Add cursor
                let cursor = chart.set(
                    "cursor",
                    am5xy.XYCursor.new(root, {
                        // xAxis: xAxis,
                        behavior: "zoomX",
                    })
                );
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);

                xAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#666666")),
                    fontSize: 10,
                });
                yAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                    fontSize: 12,
                });
                var label2 = am5.Label.new(root, {
                    text: "[#666] Date/Time [/]",
                    x: am5.p50,
                    centerY: am5.p50,
                    fontSize: 12,
                });
                xAxis.children.push(label2);
                var label1 = am5.Label.new(root, {
                    rotation: -90,
                    text: "Close Price",
                    fill: am5.color(0x666666),
                    y: am5.p50,
                    centerX: am5.p50,
                    fontSize: 12,
                });
                yAxis.children.unshift(label1);

                chart.appear(1000, 100);
            },
        },
        created() {
            if (this.$route.params.id) {
                this.getCurrecnyDetail();
            }
        },
    };
</script>
